exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-contact-contractor-js": () => import("./../../../src/pages/about/contact/contractor.js" /* webpackChunkName: "component---src-pages-about-contact-contractor-js" */),
  "component---src-pages-about-contact-dealer-js": () => import("./../../../src/pages/about/contact/dealer.js" /* webpackChunkName: "component---src-pages-about-contact-dealer-js" */),
  "component---src-pages-about-contact-js": () => import("./../../../src/pages/about/contact.js" /* webpackChunkName: "component---src-pages-about-contact-js" */),
  "component---src-pages-about-facilities-js": () => import("./../../../src/pages/about/facilities.js" /* webpackChunkName: "component---src-pages-about-facilities-js" */),
  "component---src-pages-about-history-js": () => import("./../../../src/pages/about/history.js" /* webpackChunkName: "component---src-pages-about-history-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-colorcollection-js": () => import("./../../../src/pages/colorcollection.js" /* webpackChunkName: "component---src-pages-colorcollection-js" */),
  "component---src-pages-colorlibrary-js": () => import("./../../../src/pages/colorlibrary.js" /* webpackChunkName: "component---src-pages-colorlibrary-js" */),
  "component---src-pages-colorlibrary-shelf-2-js": () => import("./../../../src/pages/colorlibrary/shelf2.js" /* webpackChunkName: "component---src-pages-colorlibrary-shelf-2-js" */),
  "component---src-pages-colorlibrary-shelf-3-js": () => import("./../../../src/pages/colorlibrary/shelf3.js" /* webpackChunkName: "component---src-pages-colorlibrary-shelf-3-js" */),
  "component---src-pages-colorlibrary-shelf-4-js": () => import("./../../../src/pages/colorlibrary/shelf4.js" /* webpackChunkName: "component---src-pages-colorlibrary-shelf-4-js" */),
  "component---src-pages-colorlibrary-shelf-5-js": () => import("./../../../src/pages/colorlibrary/shelf5.js" /* webpackChunkName: "component---src-pages-colorlibrary-shelf-5-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-paintingtools-calculator-js": () => import("./../../../src/pages/paintingtools/calculator.js" /* webpackChunkName: "component---src-pages-paintingtools-calculator-js" */),
  "component---src-pages-paintingtools-choosepaint-js": () => import("./../../../src/pages/paintingtools/choosepaint.js" /* webpackChunkName: "component---src-pages-paintingtools-choosepaint-js" */),
  "component---src-pages-paintingtools-js": () => import("./../../../src/pages/paintingtools.js" /* webpackChunkName: "component---src-pages-paintingtools-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-products-category-js": () => import("./../../../src/pages/products/category.js" /* webpackChunkName: "component---src-pages-products-category-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-colorcollection-js": () => import("./../../../src/templates/colorcollection.js" /* webpackChunkName: "component---src-templates-colorcollection-js" */),
  "component---src-templates-productcalculator-js": () => import("./../../../src/templates/productcalculator.js" /* webpackChunkName: "component---src-templates-productcalculator-js" */),
  "component---src-templates-productcategory-js": () => import("./../../../src/templates/productcategory.js" /* webpackChunkName: "component---src-templates-productcategory-js" */),
  "component---src-templates-productline-js": () => import("./../../../src/templates/productline.js" /* webpackChunkName: "component---src-templates-productline-js" */)
}

